/**
 * Script to handle the skeleton used during the loading of text and images.
 * @author See list of contributors {@link https://gitlab.com/sia-insa-lyon/portailva/-/graphs/master?ref_type=heads}
 * @license AGPL-3.0-or-later
 */

/** A skeleton type
 * @typedef {string} SkeletonType
 */
/** Enum used to link different skeleton types used by the directory modal
 * @readonly
 * @enum {SkeletonType}
 * @see {resetModal}
 */
const SKELETON_TYPE = {
    TEXT: 'text',
    TEXT_WITH_BADGE: 'textBadged',
    TEXT_WITH_THUMB: 'textThumbed',
    SHORT_TEXT: 'shortText'
};

/** Function to remove the `skeleton` class from an HTML element
 *
 * @param {Event} event Event fired by the image contained by the `skeleton` element
*/
function removeSkeleton(event) {
    document.querySelector(`#${event.currentTarget.parentElement.id}`).classList.remove('skeleton');
}

/** Function to remove the `skeleton` class from every div HTML Element containing an image */
function handleSkeletonImg() {
    document.querySelectorAll('div .skeleton > img').forEach((el) => {
        el.addEventListener('load', removeSkeleton);
        el.addEventListener('error', removeSkeleton);
    });
}

export { SKELETON_TYPE, removeSkeleton, handleSkeletonImg };
