/**
 * Script to add a mechanism to display the directory linked to an association.
 * @author See list of contributors {@link https://gitlab.com/sia-insa-lyon/portailva/-/graphs/master?ref_type=heads}
 * @license AGPL-3.0-or-later
 */
import $ from 'jquery';
import 'bootstrap';
import './types';
import { SKELETON_TYPE, handleSkeletonImg } from './skeleton';

/** Function to generate HTML for the initial state of the modal
 *
 * @param {SKELETON_TYPE} skeletonType Type of skeleton to generate
 * @param {number} numberOfElement Number of skeleton text element
 * @param {?string} titleContent (Optional) Content of h5 element
 * @return {string} HTML skeleton div element content
 */
function getHTMLInitialSection(skeletonType, numberOfElement, titleContent = null) {
    let code = '';
    let skeletonClass;

    if (titleContent) {
        code = code.concat('<h5>', titleContent, '</h5>');
    }

    switch (skeletonType) {
    case SKELETON_TYPE.TEXT_WITH_THUMB:
        code = code.concat('<div class="float-right skeleton col-3 skeletonThumb"></div>');
        skeletonClass = 'skeletonText';
        break;
    case SKELETON_TYPE.TEXT_WITH_BADGE:
        skeletonClass = 'skeletonTitle';
        break;
    default:
        skeletonClass = 'skeletonShortText';
    }

    for (let i = 0; i < numberOfElement; i++) {
        code = code.concat(`<div class="skeleton ${skeletonClass}"></div>`);
    }
    if (skeletonType === SKELETON_TYPE.TEXT_WITH_BADGE) {
        code = code.concat('<div class="skeleton skeletonBadge"></div>');
    }

    return code;
}

function setHTMLTitleSection(name, acronym, category) {
    const $ref = $('#modal-association-title');
    $ref.empty().append(`${name} `);
    if (acronym) {
        $ref.append(`<small class="text-muted">(${acronym})</small>`);
    }
    if (category.color) {
        $ref.append(` <span class="badge badge-pill" style="color: #fff; background-color: ${category.color};">${category.name}</span>`);
    } else {
        $ref.append(` <span class="badge badge-pill badge-secondary">${category.name}</span>`);
    }
}

function setHTMLDescriptionSection(description, logo) {
    const $ref = $('#modal-association-description');
    $ref.empty().append('<h5>Description</h5>');
    if (logo) {
        $ref.append(`<img src="${logo}" alt="Logo" class="float-right col-3"/>`);
    }
    $ref.append(`<p>${(description) ? description.replace(/\r\n/g, '<br/>') : '<em>Non défini</em>'}</p>`);
}

function setHTMLPlaceSection(name, lat, long) {
    const locationText = (name && lat && long) ? `<a href="https://www.openstreetmap.org/?mlat=${lat}&mlon=${long}&zoom=18">${name}</a>` : '<em>Non défini</em>';
    $('#modal-association-place').empty().append(`<h5>Lieu</h5><p><i aria-hidden="true" class="fa fa-fw fa-location-arrow"></i> ${locationText}</p>`);
}

function setHTMLWebsiteSection(link) {
    const linkText = (link) ? `<a href="${link}" style="word-wrap: break-word;">${link}</a>` : '<em>Non défini</em>';
    $('#modal-association-website').empty().append(`<h5>Site web</h5><p><i aria-hidden="true" class="fa fa-fw fa-external-link"></i> ${linkText}</p>`);
}

function setHTMLNetworkSection(facebook, twitter, instagram, discord) {
    const getSocialNetworkLink = (link, name) => ((link) ? `<a href="${link}">${name}</a>` : '<em>Non défini</em>');
    $('#modal-association-network').empty().append(`
        <h5>Réseaux sociaux</h5>
        <p>
            <i aria-hidden="true" class="fa fa-fw fa-facebook-f"></i> ${getSocialNetworkLink(facebook, 'Facebook')}<br/>
            <i aria-hidden="true" class="fa fa-fw fa-twitter"></i> ${getSocialNetworkLink(twitter, 'Twitter')}<br/>
            <i aria-hidden="true" class="fa fa-fw fa-instagram"></i> ${getSocialNetworkLink(instagram, 'Instagram')}<br/>
            <i aria-hidden="true" class="fab fa-fw fa-discord"></i> ${getSocialNetworkLink(discord, 'Discord')}<br/>
        </p>
    `);
}

function setHTMLContactSection(mail, phone, phoneSource) {
    const defaultNode = '<em>Non défini</em>';
    const mailText = (mail) ? `<a href="mailto:${mail}">${mail}</a>` : defaultNode;
    const phoneSourceText = phoneSource ? ` (${phoneSource})` : '';
    const phoneText = (phone) ? `<a href="tel:${phone}">${phone}</a>${phoneSourceText}` : defaultNode;
    $('#modal-association-contact').empty().append(`
        <h5>Contact</h5>
        <p>
            <i aria-hidden="true" class="fa fa-fw fa-envelope-o"></i> ${mailText}<br/>
            <i aria-hidden="true" class="fa fa-fw fa-phone"></i> ${phoneText}
        </p>
    `);
}

function setHTMLHoursSection(hours) {
    const formatTime = (time) => time.substring(0, time.length - 3);
    const formatDay = (day) => ['Inconnu', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'][day];
    const $ref = $('#hours');
    $ref.empty().append('<h5>Horaires de permanences</h5>');

    if (hours && hours.length > 0) {
        const listElement = document.createElement('ul');
        listElement.className = 'list-group';
        const $refList = $(listElement);

        hours.forEach((openingHours) => $refList.append(`<li class="list-group-item">${formatDay(openingHours.day)} de ${formatTime(openingHours.begins_at)} à ${formatTime(openingHours.ends_at)}</li>`));

        $ref.append($refList);
    } else {
        $ref.append('<em>Aucun horaire défini</em>');
    }
}

function setHTMLEventsSection(eventList, eventToDisplay = null) {
    const $ref = $('#events');
    $ref.empty();
    if (!eventList || eventList.length < 1) {
        $ref.append('<em>Aucun évènement à venir.</em>');
    } else {
        eventList.forEach((event, index) => {
            const divRoot = document.createElement('div');
            divRoot.className = 'item';

            $(divRoot).append(`<a data-toggle="collapse" data-parent="#events" href="#event-${event.id}"aria-expanded="true" aria-controls="event">${event.name} (${event.type})</a>`);

            const divChild = document.createElement('div');
            divChild.id = `event-${event.id}`;
            divChild.className = 'collapse';
            if ((eventToDisplay && event.id === eventToDisplay) || index === 0) {
                divChild.className = 'collapse show';
            }
            divChild.setAttribute('role', 'tabpanel');

            const $refDivChild = $(divChild);
            if (event.logo_url) {
                $refDivChild.append(`<img src="${event.logo_url}" alt="Logo" class="float-right col-3" />`);
            }
            $refDivChild.append(`<p>${event.description.replace(/\r\n/g, '<br/>')}</p>`);
            $refDivChild.append('<div class="row mb-2">');
            $refDivChild.find('div.row').append('<div class="col-md-6">');
            $refDivChild.find('div.col-md-6').append(`<i aria-hidden="true" class="fa fa-fw fa-calendar"></i> ${event.begins_at} - ${event.ends_at}`);
            $refDivChild.find('div.row').append('<div class="col-md-6">');
            $refDivChild.find('div.col-md-6:nth-child(2)').append('<i aria-hidden="true" class="fa fa-fw fa-location-arrow"></i> ');

            const { lat, long, name } = event.place;
            $refDivChild.find('div.col-md-6:nth-child(2)').append((name && lat && long) ? `<a href="https://www.openstreetmap.org/?mlat=${lat}&mlon=${long}&zoom=18">${name}</a>` : '<em>Non défini</em>');

            if (event.website_url || event.facebook_url) {
                $refDivChild.append('<div class="row mb-2">');
                if (event.website_url) {
                    $refDivChild.find('div.row').last().append(`<div class="col-md-6"><i aria-hidden="true" class="fa fa-fw fa-link"></i> <a href="${event.website_url}">Page web</a>`);
                }
                if (event.facebook_url) {
                    $refDivChild.find('div.row').last().append(`<div class="col-md-6"><i aria-hidden="true" class="fa fa-fw fa-facebook"></i> <a href="${event.facebook_url}">Evènement Facebook</a>`);
                }
            }

            if (event.prices && event.prices.length > 0) {
                $refDivChild.append('<hr/><h6><i aria-hidden="true" class="fa fa-fw fa-money"></i> Tarifs de l\'évènement</h6>');
                const listElement = document.createElement('ul');
                listElement.className = 'list-group';
                const $refList = $(listElement);

                event.prices.forEach((priceElement) => {
                    const priceVariableText = (priceElement.price && !priceElement.is_variable) ? (`<strong>${priceElement.price} €</strong>`) : '';
                    const priceFreeText = priceElement.is_variable ? 'Prix libre' : '';
                    const priceVAText = priceElement.is_va ? ' - (Tarif VA)' : '';
                    $refList.append(
                        `<li class="list-group-item list-group-item-action flex-column"><div class="row justify-content-between align-items-center">
                            <div class="col-md-6"><strong>${priceElement.name}</strong></div>
                            <div class="col-md-6">${priceVariableText + priceFreeText + priceVAText}</div>
                        </div></li>`
                    );
                });
                $refDivChild.append($refList);
                $refDivChild.append('<br/>');
            }

            $(divRoot).append(divChild);
            $ref.append(divRoot);
        });
    }
}

function resetModal() {
    $('#modal-association-title').html(getHTMLInitialSection(SKELETON_TYPE.TEXT_WITH_BADGE, 1));
    $('#modal-association-description').html(getHTMLInitialSection(SKELETON_TYPE.TEXT_WITH_THUMB, 4, 'Description'));
    $('#modal-association-place').html(getHTMLInitialSection(SKELETON_TYPE.SHORT_TEXT, 1, 'Lieu'));
    $('#modal-association-website').html(getHTMLInitialSection(SKELETON_TYPE.SHORT_TEXT, 1, 'Site web'));
    $('#modal-association-network').html(getHTMLInitialSection(SKELETON_TYPE.SHORT_TEXT, 3, 'Réseaux sociaux'));
    $('#modal-association-contact').html(getHTMLInitialSection(SKELETON_TYPE.SHORT_TEXT, 2, 'Contact'));
    $('#hours').html(getHTMLInitialSection(SKELETON_TYPE.SHORT_TEXT, 2, 'Horaires de permanences'));
    $('#events').html(getHTMLInitialSection(SKELETON_TYPE.TEXT, 3));
}

let lastModalAssociationId = 0;

/** Function to generate modal for a specific association and open it !
 *
 * @param {string} baseURL URL of the endpoint to use
 * @param {number} assoId ID of the association to display
 * @param {?number} eventId (Optional) ID of the event to show directly to user
 */
function showDirectoryModal(baseURL, assoId, eventId = null) {
    $.ajax({
        url: `${baseURL + assoId}/`,
        method: 'GET',
        beforeSend: () => {
            resetModal();
            $('#assoDetails .alert').remove();
        },
        complete: () => {
            document.querySelectorAll('#assoDetails div.btn-group a').forEach((link) => {
                const url = link.href;
                const idPos = url.lastIndexOf(lastModalAssociationId);
                link.setAttribute('href', url.substring(0, idPos) + url.substring(idPos, url.length).replace(lastModalAssociationId, `${assoId}`));
            });
            lastModalAssociationId = assoId;
            $('#assoDetails').modal('show');
            $(`.nav-tabs a[href="#${(eventId) ? 'events' : 'info'}"]`).tab('show');
        }
    }).done(
        /** @param {DirectoryDetailPVA} data */
        (data) => {
            setHTMLTitleSection(data.name, data.acronym, data.category);
            setHTMLDescriptionSection(data.description, data.logo_url);
            setHTMLWebsiteSection(data.website_url);
            setHTMLNetworkSection(
                data.facebook_url,
                data.twitter_url,
                data.instagram_url,
                data.discord_url
            );
            setHTMLPlaceSection(data.location.name, data.location.lat, data.location.long);
            setHTMLContactSection(
                data.contact_address,
                data.public_phone.phone,
                data.public_phone.source
            );
            setHTMLHoursSection(data.opening_hours);
            setHTMLEventsSection(data.related_events, eventId);
        }
    ).fail((err) => {
        $('div.modal').prepend(
            `<div class="alert alert-danger" role="alert">Une erreur est survenue, voici le détail : ${(err.responseJSON && err.responseJSON.detail) ? err.responseJSON.detail : 'erreur inattendue'}</div>`
        );
    });
}

document.addEventListener('DOMContentLoaded', () => {
    handleSkeletonImg();
    const url = document.querySelector('meta[property="pva:modal:api"]')?.getAttribute('content');
    if (!url) {
        throw new Error('Cannot mount the directory modal script, the API URL is missing!');
    }

    /** Function to bind an event from a button to the association modal
     *
     * @param  {Event} event Event fired by the button
     */
    function bindButtonToModal(event) {
        /** @type {HTMLElement} */
        const modalBtn = event.currentTarget;
        const assoId = parseInt(modalBtn.getAttribute('data-js-association'), 10);
        const eventId = parseInt(modalBtn.getAttribute('data-js-event'), 10);
        if (Number.isNaN(assoId)) {
            throw new Error('Cannot show the directory modal script, the association ID is missing!');
        }
        showDirectoryModal(url, assoId, (!Number.isNaN(eventId)) ? eventId : null);
    }

    const btnModal = document.querySelectorAll('button[data-js-association]');
    btnModal.forEach((btn) => {
        btn.addEventListener('click', bindButtonToModal);
    });
});

export { showDirectoryModal, resetModal };
